@import "../../../styles/vars.scss";
@import "../../../styles/colors.scss";
@import "../../../styles/mixins.scss";
@import "../../../styles/fonts.scss";

.catalog {
  gap: 24px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 56px;

  @include breakpoint(wide-l) {
    grid-template-columns: 1fr 1fr;
  }

  @include breakpoint(desktop) {
    grid-template-columns: 1fr;
  }

  &__container {
    @extend %flex-column;

    gap: 16px;
    width: 100%;
    align-items: center;
    padding: 64px 10% 40px 10%;
    background-color: #f3f3f3;

    @include breakpoint(large-d-max) {
      padding: 40px 5%;
    }

    @include breakpoint(desktop) {
      padding: 32px 0px 24px 0px;
    }

    a {
      color: white;
    }

    h2 {
      font-size: 48px;
      font-weight: bold;

      @include breakpoint(desktop) {
        font-size: 24px;
        text-align: center;
      }
    }

    p {
      width: 43%;
      text-align: center;
      line-height: 25.6px;

      @include breakpoint(desktop) {
        width: 90%;
      }
    }
  }

  & > div {
    @extend %flex-column;

    width: 100%;
    padding: 24px 32px;
    gap: 8px;
    align-items: start;
    background-color: white;
    border-radius: 24px;

    > h4 {
      width: 100%;
      font-size: 32px;
      margin-bottom: 16px;
      font-weight: 700;

      span {
        font-size: 16px;
        color: gray;
        font-weight: lighter;
      }
    }

    & > p {
      width: 100%;
      text-align: start;
      font-size: 24px;
      opacity: 0.8;
    }

    > span {
      font-size: 16px;
      color: gray;
      font-weight: lighter;
    }

    & > div {
      width: 100%;
      display: grid;
      justify-items: center;
      grid-template-columns: 1.2fr 2fr 1.2fr;
      background-color: #f6f6f6;
      border-radius: 15.33px;
      padding: 9px 0px;

      p {
        width: 100%;
      }

      img {
        width: auto;
      }
    }

    img {
      width: 100%;
    }

    > button {
      padding: 14px;
      margin-top: 16px;
      background-color: white;
      border: 0.75px solid #0f0f0f;
      width: 100%;
      border-radius: 64px;
      cursor: pointer;
      transition: 0.2s;
      font-size: 20px;

      &:hover {
        color: white;
        background-color: #0f0f0f;
      }
    }
  }

  .catalog > div {
    img {
      width: 100%;
    }
  }
}


catalog_page{
  margin: 0px;
}