@import "../../../styles/vars.scss";
@import "../../../styles/colors.scss";
@import "../../../styles/mixins.scss";
@import "../../../styles/fonts.scss";

main {
  @extend %flex-center-column;

  width: 100%;
  padding: 10vh 10%;
  position: relative;
  background-color: #000000;

  @include breakpoint(large-d-max) {
    padding: 10vh 5%;
  }

  @include breakpoint(desktop) {
    padding: 6vh 1.5% !important;
  }

  & > div {
    @extend %flex-center-column;

    width: 80%;
    height: 160px;
    background-color: #ffffff;
    opacity: 0.95;
    border-radius: 16px;
    padding: 10px;
    gap: 25px;
    text-align: center;
    position: absolute;
    left: 10%;
    bottom: 0;
    transform: translate(0%, 50%);
    box-shadow: 0px 22.93px 27.51px -4.59px #10182814;
    font-size: 20px;

    @include breakpoint(large-d-max) {
      width: 90%;
      left: 5%;
    }

    @include breakpoint(desktop) {
      width: 97%;
      left: 1.5%;
      height: unset;
    }
  }

  h2 {
    color: white;
    width: 75%;
    text-align: center;
    font-size: 40px;
    margin-bottom: 50px;
    font-weight: bold;

    @include breakpoint(desktop) {
      font-size: 16px;
    }
  }


  img {
    width: 80%;
  }
}

.p {
  font-size: 20px !important;
  @media (max-width: 420px){
    font-size: 14px !important;
  }
}