@import "../../../styles/vars.scss";
@import "../../../styles/colors.scss";
@import "../../../styles/mixins.scss";
@import "../../../styles/fonts.scss";

footer {
  @extend %flex-between;

  background-color: #0f0f0f;
  color: white;
  padding: 34px 10%;
  border-top: 1px solid #494a4a;

  @include breakpoint(desktop) {
    padding: 34px 5%;
    flex-direction: column;
    gap: 20px;
  }

  @include breakpoint(large-d-max) {
    padding: 34px 5%;
  }

  a {
    text-decoration: none;
    color: #d9dbe1;
  }

  p {
    color: #d9dbe1;
  }

  ul {
    @extend %flex-justify-center;

    list-style: none;
    gap: 34px;
  }
}
