@import "../../styles/vars.scss";
@import "../../styles/colors.scss";
@import "../../styles/mixins.scss";
@import "../../styles/fonts.scss";

@import "/src/components/shared/Catalog/Catalog.module.scss";

.catalog__container {
  align-items: start;
  gap: 0;

  @include breakpoint(desktop) {
    padding: 16px 1.5%;
  }

  @include breakpoint(desktop) {
    padding: 16px 1.5%;
  }

  > div {
    margin-top: 40px;

    @include breakpoint(desktop) {
      margin-top: 24px;
    }
  }
}
