@import "../../../styles/vars.scss";
@import "../../../styles/colors.scss";
@import "../../../styles/mixins.scss";
@import "../../../styles/fonts.scss";

.AboutUS {
  display: flex;
  background-color: #0f0f0f;
  color: white;

  @include breakpoint(desktop) {
    flex-direction: column;
  }

  & > div {
    @extend %flex-column;

    gap: 24px;
    padding: 74px 3% 0px 10%;
    width: 45%;

    @include breakpoint(large-d-max) {
      padding: 74px 3% 20px 5%;
    }

    @include breakpoint(desktop) {
      width: 100%;
      padding: 74px 3% 20px 1.5%;
    }

    & > h1 {
      font-size: 48px;
    }

    & > h3 {
      font-size: 24px;
      margin-top: 32px;
    }
  }

  & > img {
    width: 55%;

    @include breakpoint(desktop) {
      width: 100%;
      order: -1;
      padding: 0;
    }
  }

  p {
    line-height: 24px;
  }

  h1,
  h3 {
    font-weight: bold;
  }
}
