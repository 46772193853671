@import "../../../styles/vars.scss";
@import "../../../styles/colors.scss";
@import "../../../styles/mixins.scss";
@import "../../../styles/fonts.scss";

.aboutInfo {
  @extend %flex-column;

  padding: 96px 10%;

  @include breakpoint(large-d-max) {
    padding: 96px 5%;
  }

  @include breakpoint(desktop) {
    padding: 0px 0px 20px 0px;
  }

  > div {
    display: flex;
    gap: 20px;
    margin-bottom: 8px;

    @include breakpoint(desktop) {
      flex-direction: column;
    }

    & > img {
      width: 45%;

      @include breakpoint(desktop) {
        width: 100%;
      }
    }

    & > div {
      @extend %flex-column;

      width: 55%;
      gap: 16px;

      @include breakpoint(desktop) {
        width: 100%;
        padding: 10px;
      }

      h2 {
        margin-top: 32px;
        font-size: 24px;
      }

      ul {
        margin-left: 25px;
        line-height: 24px;

        li {
          list-style: disc;
        }
      }
    }

    .aboutInfo > div > div {
      @extend %flex-column;

      width: 55%;
      gap: 16px;
    }
  }

  h2 {
    margin-top: 32px;
    margin-bottom: 16px;
    font-weight: bold;
    font-size: 24px;
  }

  p {
    line-height: 24px;
  }

  @include breakpoint(desktop) {
    > h2,
    > p {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}
