@import "../../../styles/vars.scss";
@import "../../../styles/colors.scss";
@import "../../../styles/mixins.scss";
@import "../../../styles/fonts.scss";

.services {
  @extend %flex-column-center;

  width: 100%;
  padding: 100px 10% 76px 10%;
  gap: 16px;
  background-color: #0f0f0f;
  color: white;

  @include breakpoint(desktop) {
    padding: 32px 5px;
  }

  p {
    width: 43%;
    text-align: center;
    line-height: 25.6px;

    @include breakpoint(desktop) {
      width: 95%;
    }
  }

  h2 {
    font-size: 48px;
    font-weight: bold;

    @include breakpoint(desktop) {
      font-size: 24px;
    }
  }

  > div {
    margin-top: 54px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    @include breakpoint(desktop) {
      grid-template-columns: 1fr;
    }
  }
}

.service {
  @extend %flex-center-column;

  width: 100%;
  gap: 8px;

  p {
    width: 70%;
    color: #d6d6d6;

    @include breakpoint(desktop) {
      width: 95%;
    }
  }

  h3 {
    font-size: 24px;
    margin-top: 16px;
    font-weight: bold;
  }

  > div {
    @extend %flex-center;

    width: 48px;
    height: 48px;
    background-color: white;
    border-radius: 50%;
  }
}
