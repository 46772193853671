@import "../../styles/vars.scss";
@import "../../styles/colors.scss";
@import "../../styles/mixins.scss";
@import "../../styles/fonts.scss";

.error {
  width: 100%;
  height: 74.9vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  h2 {
    font-size: 42px;
  }
}
