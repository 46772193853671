@import "../../../styles/vars.scss";
@import "../../../styles/colors.scss";
@import "../../../styles/mixins.scss";
@import "../../../styles/fonts.scss";

@import "/src/styles/local.scss";

.navbar {
  box-shadow: 0 1px 4px rgb(146 161 176 / 15%);

  .menu-items {
    @extend %flex-column;

    &.active {
      transform: translateX(0);
    }

    position: absolute;
    padding-top: 150px;
    box-shadow: inset 0 0 2000px rgba(255, 255, 255, 0.5);
    height: 100vh;
    width: 100%;
    transform: translate(-150%);
    transition: transform 0.5s ease-in-out;
    text-align: center;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.8);

    li {
      font-size: 1.5rem;
      font-weight: 500;
    }
  }

  .nav-container {
    display: block;
    position: relative;
    height: 105px;
    cursor: pointer;

    li {
      list-style: none;
    }

    a {
      text-decoration: none;
      color: white;
      font-weight: 500;
      font-size: 1.2rem;
      padding: 0.7rem;
    }

    .menu-items a {
      font-size: 30px;
      transition: 0.3s;

      &:hover {
        font-size: 30px;
        font-size: 35px;
      }
    }
  }
}

.nav-container {
  display: block;
  position: relative;
  height: 105px;

  .hamburger-lines .line3.active {
    transform: rotate(-45deg);
  }

  .hamburger-lines .line1.active {
    transform: rotate(45deg);
  }

  .hamburger-lines {
    @extend %flex-column-between;

    height: 26px;
    width: 32px;
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
    z-index: 11;
    left: 10%;

    @include breakpoint(large-d-max) {
      left: 5%;
    }

    .line {
      display: block;
      height: 4px;
      width: 100%;
      border-radius: 10px;
      background: #ffffff;
    }

    .line.catalog,
    .line.about {
      background: black;
    }

    .line1 {
      transform-origin: 0% 0%;
      transition: transform 0.4s ease-in-out;
    }

    .line2 {
      transition: transform 0.2s ease-in-out;
    }

    .line3 {
      transform-origin: 0% 100%;
      transition: transform 0.4s ease-in-out;
    }
  }
}

nav {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 100;
  background-color: #0f0f0f;

  &.catalog,
  &.about {
    background-color: white;
  }

  .btn {
    top: 50%;
    right: 0;
    z-index: 12;
    margin-right: 10%;
    position: absolute;
    transform: translate(0%, -50%);

    &.catalog,
    &.about {
      background-color: white;
      color: black;
      border: 1px solid black;

      &:hover {
        background-color: #545663;
        color: white;
        border-color: #545663;
      }
    }

    @include breakpoint(large-d-max) {
      margin-right: 5%;
    }

    @include breakpoint(desktop) {
      display: none;
    }
  }
}

.logo {
  left: 50%;
  top: 50%;
  z-index: 12;
  font-size: 32px;
  color: #ffffff;
  position: absolute;
  transform: translate(-50%, -50%);
}
