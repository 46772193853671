@import "../../../styles/vars.scss";
@import "../../../styles/colors.scss";
@import "../../../styles/mixins.scss";
@import "../../../styles/fonts.scss";

.about {
  @extend %flex-column-center;

  width: 100%;
  padding: 64px 10% 40px 10%;
  gap: 16px;

  @include breakpoint(large-d-max) {
    padding: 64px 5% 40px 5%;
  }

  @include breakpoint(desktop) {
    padding: 32px 12px;
  }

  &__info {
    @extend %flex-column;

    position: absolute;
    left: 0;
    width: 50%;
    top: 50%;
    transform: translate(0%, -50%);
    gap: 24px;

    @include breakpoint(desktop) {
      width: 100%;
      position: unset;
      transform: unset;
    }

    > div > div {
      @include breakpoint(desktop) {
        &:last-child {
          width: 90%;
        }
      }

      p {
        @include breakpoint(desktop) {
          width: 95%;
        }
      }
    }
  }

  &__img {
    @extend %flex-end;

    width: 60%;
    background-color: #f5f5f5;
    padding: 20vh 10%;
    border-radius: 24px;
    margin-top: 22px;

    @include breakpoint(desktop) {
      display: none;
    }
  }

  > div {
    @extend %flex-end;

    width: 100%;
    position: relative;
  }

  p {
    width: 43%;
    text-align: center;
    line-height: 25.6px;

    @include breakpoint(desktop) {
      width: 95%;
    }
  }

  h2 {
    font-size: 48px;

    @include breakpoint(desktop) {
      font-size: 24px;
      font-weight: bold;
    }
  }
}

.info {
  @extend %flex-align;

  justify-content: start;
  padding: 30px 35px;
  gap: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  background-color: white;

  @include breakpoint(desktop) {
    flex-direction: column;
    align-items: start;
  }

  h3 {
    text-align: start;
    font-size: 24px;
    margin-bottom: 12px;
    font-weight: bold;
  }

  p {
    width: 80%;
    text-align: start;
    line-height: 25.6px;
  }
}

.for-img {
  padding: 40px 15px;
  background-color: #f5f5f5;
  border-radius: 16px;

  & > img {
    max-width: unset;
  }
}
