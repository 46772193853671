@import "../../../styles/vars.scss";
@import "../../../styles/colors.scss";
@import "../../../styles/mixins.scss";
@import "../../../styles/fonts.scss";

.marks {
  @extend %flex-column;

  padding: 10%;
  padding-top: 160px;
  background-color: white;
  gap: 48px;
  font-size: 18px;

  @include breakpoint(large-d-max) {
    padding: 10% 5%;
  }

  @include breakpoint(desktop) {
    padding: 15% 12px 5% 12px;
  }

  @media screen and (max-width: 550px) {
    padding: 25% 12px 5% 12px;
  }

  &__container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 24px 16px;

    @include breakpoint(wide-l) {
      grid-template-columns: repeat(4, 1fr);
    }

    @include breakpoint(desktop) {
      grid-template-columns: repeat(3, 1fr);
    }

    @include breakpoint(tablet-m) {
      grid-template-columns: 1fr 1fr;
    }

    & > div {
      @extend %flex-center-column;

      width: 100%;
      padding: 25px;
      gap: 10px;
      background-color: #f3f3f3;
      border-radius: 8px;
    }

    .none {
      @include breakpoint(desktop) {
        display: none;
      }

      &:last-child {
        @include breakpoint(tablet-m) {
          display: flex;
        }
      }
    }
  }

  h3 {
    font-weight: 400;
  }

  h2 {
    font-size: 27px;
    font-weight: bold;
  }
}
