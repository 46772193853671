@import "../../../styles/vars.scss";
@import "../../../styles/colors.scss";
@import "../../../styles/mixins.scss";
@import "../../../styles/fonts.scss";

.modal {
  @extend %flex-center;

  width: 100%;
  height: 100vh;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.9);
  top: 0;
  left: 0;
  z-index: 1000000;
  transition: 0.2s;
  transform: scale(0);

  &.active {
    transform: scale(1);
  }

  & > div {
    @extend %flex-center-column;

    width: 300px;
    height: 200px;
    color: white;
    border: 1px solid white;
    gap: 20px;

    > div {
      a {
        @extend %flex-center;
        gap: 15px;
        font-size: 20px;
        color: white;
      }
    }
  }
}
