@import "../../../styles/vars.scss";
@import "../../../styles/colors.scss";
@import "../../../styles/mixins.scss";
@import "../../../styles/fonts.scss";

.container {
  @extend %flex-column;

  background-size: cover;
  min-height: 100vh;

  &__main {
    flex: 1 1 auto;
  }
}
main {
  @extend %flex-center-column;

  width: 100%;
  padding: 10vh 10%;
  position: relative;
  background-color: #000000;

  @media (max-width: 1700px) {
    padding: 10vh 5%;
  }
}

.for-sticky {
  position: relative;
}
